/*
* 업무구분:
* 화면 명:
* 화면 설명:
* 화면 접근권한:
*/
<template>
  <ur-page-container title="기본정보" :show-title="true" type="subpage" @on-header-left-click="fn_back">
    <!-- 라이프맵핑_전체 S -->
    <ur-box-container direction="column" alignV="start" alignH="start" componentid="" class="nw-qw-wrap nw-life-wrap">
      <!-- 라이프맵핑_기본정보 S -->
      <ur-box-container class="nw-title-box mb30">
        <strong>고객님의</strong>
      </ur-box-container>

      <mo-validate-watcher ref="watcherStep0">
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-add-area pal0">
          <ur-box-container alignV="start" componentid="" direction="column" class="add-box txt-add-box">
            <span class="title must sm">성함은</span>
            <mo-text-field class="form-input-name" :rules="validateRuleMobslChnlCustNm" v-model="ctLifeMappRsltInfoSVO.mobslChnlCustNm" underline placeholder="" maxlength="30" />
          </ur-box-container>
          <ur-box-container alignV="start" componentid="" direction="column" class="add-box txt-add-box">
            <span class="title must sm">현재나이는</span>
            <mo-decimal-field numeric :rules="validateRuleCustCurAge" v-model="ctLifeMappRsltInfoSVO.custCurAge" underline placeholder="" @blur="fn_chkCurAge" max-numlength="3"/>
            <span class="input-txt">세</span>
          </ur-box-container>
          <ur-box-container alignV="start" componentid="" direction="column" class="add-box txt-add-box">
            <span class="title must sm">은퇴(예상)나이는</span>
            <mo-decimal-field numeric :rules="validateRuleCustRtmAge" v-model="ctLifeMappRsltInfoSVO.custRtmAge" underline placeholder="" @blur="fn_chkRtmAge" max-numlength="3"/>
            <span class="input-txt">세</span>
            <span class="txt">우리나라의 평균 은퇴 연령은 63세에요.</span>
          </ur-box-container>
          <ur-box-container alignV="start" componentid="" direction="column" class="add-box txt-add-box">
            <span class="title must sm">예상 수명은</span>
            <mo-decimal-field numeric :rules="validateRuleCustExptLolAge" v-model="ctLifeMappRsltInfoSVO.custExptLolAge" underline placeholder="" @blur="fn_chkExptLolAge" max-numlength="3"/>
            <span class="input-txt">세</span>
            <span class="txt">
              우리나라의 평균 기대수명은 83.5세에요.<br/>
              *출처 KOSIS(통계청, 2020년기준)
            </span>
          </ur-box-container>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
            <div class="relative-div">
              <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue"  @click="fn_openChart" >다음</mo-button>
            </div>
          </ur-box-container>
        </ur-box-container>
      </mo-validate-watcher>
      <!-- 라이프맵핑_기본정보 E-->
    </ur-box-container>
    <!-- 라이프맵핑_전체 E-->
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPCT703M",
    screenId: "MSPCT703M",
    components: {
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {},
    mounted() {
      this.$bizUtil.insSrnLog(this.$options.screenId)
      
      console.log('============== MSPCT703M 들어왔땅~~~')
      console.log('==== this.$route.params >> ', this.$route.params)
      
      this.$nextTick(() => {
        if(this.$route.params.ctLifeMappRsltInfoSVO != null && this.$route.params.ctLifeMappRsltInfoSVO != undefined){
          console.log(' ==== this.$route.params.ctLifeMappRsltInfoSVO >>', this.$route.params.ctLifeMappRsltInfoSVO)
          this.ctLifeMappRsltInfoSVO = this.$bizUtil.cloneDeep(this.$route.params.ctLifeMappRsltInfoSVO)
        }
  
        let lifeMappFileId = this.ctLifeMappRsltInfoSVO.lifeMappFileId
        let tmpFileYn = this.ctLifeMappRsltInfoSVO.tmpFileYn

        if(this.$bizUtil.isEmpty(lifeMappFileId) && this.$bizUtil.isEmpty(tmpFileYn)){
          this.ctLifeMappRsltInfoSVO.tmpFileYn = '1'
        }
       
      })
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        ctLifeMappRsltInfoSVO : {
          lifeMappFileId : '', //라이프매핑파일ID
          lifeMappFileNm : '', //라이프매핑파일명
          lifeMappFileDeltYn : '', //라이프매핑파일삭제여부
          tmpFileYn : '', //임시파일여부
          mobslChnlCustNm : '', //모바일영업채널고객명
          mobslChnlCustId : '', //모바일영업채널고객Id
          custCurAge : null, //고객현재연령
          custRtmAge : null, //고객퇴직연령
          custExptLolAge : null, //고객예상수명연령
          rtmBefMntBasIncmAmt : null, //퇴직전월기본소득금액
          rtmBefMntAddmIncmAmt : null, //퇴직전월추가소득금액
          rtmBefMntLvgct : null, //퇴직전월생활비
          rtmBefLnPrnc : null, //퇴직전월대출원금
          rtmBefLnMntRedam : null, //퇴직전대출월상환액
          rtmBefChldEduCst : null, //퇴직전자녀교육비용
          rtmBefEvtAmt : null, //퇴직전이벤트금액
          rtmBefMntInsrSavAmt : null, //퇴직전월보험저축금액
          rtmBefEtcExpntAmt : null, //퇴직전기타지출금액
          rtmBefTotIncomAmt : null, //퇴직전총수입금액
          rtmBefTotExpntAmt : null, //퇴직전총지출금액
          rtmAftMntLvgct : null, //퇴직후월생활비
          rtmAftChldMargCst : null, //퇴직후자녀결혼비용
          curChldFgr : null, //현재자녀수
          rtmAftHlthPrm : null, //퇴직후월의료비
          rtmAftMntMdexp : null, //퇴직후건강보험료
          rtmAftEtcExpntAmt : null, //퇴직후기타지출금액
          rtmAftMntBasIncmAmt : null, //퇴직후월기본소득금액
          rtmAftMntBasIncmPrd : null, //퇴직후월기본소득기간
          rtmAftMntPanutAmt : null, //퇴직후월국민연금금액
          rtmAftMntNpensAmt : null, //퇴직후월개인연금금액
          exptTotRtmAmt : null, //예상총퇴직금액
          rtmAftAddmMntIncmAmt : null, //퇴직후추가월소득금액
          rtmAftTotExpntAmt : null, //퇴직후총수입금액
          rtmAftTotIncomAmt : null //퇴직후총지출금액
        },
        
        validateRuleMobslChnlCustNm: [
          v => !!v || '필수입력항목입니다',
          v => v.length >= 2 || '2자 이상 입력하세요.',
          v => !(v.search('[^a-zA-Z가-힣ㄱ-ㅎㅏ-ㅣ\s]') > -1) || '특수문자,숫자는 입력할수 없습니다.',
        ],
        validateRuleCustCurAge: [
          v => !!v || '필수입력항목입니다.'
        ],
        validateRuleCustRtmAge: [
          v => !!v || '필수입력항목입니다.'
        ],
        validateRuleCustExptLolAge: [
          v => !!v || '필수입력항목입니다.'
        ],
       
      }
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {},
    /***********************************************************************************
     * watch 함수 정의 영역                                                         *
     ***********************************************************************************/
    watch: {},
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      fn_chkCurAge(e) {
        if(e.target.value < 18) {
          this.ctLifeMappRsltInfoSVO.custCurAge = 18
        }else if(e.target.value > 120) {
          this.ctLifeMappRsltInfoSVO.custCurAge = 120
        }
      },
      fn_chkRtmAge(e) {
        if(e.target.value < 18) {
          this.ctLifeMappRsltInfoSVO.custRtmAge = 18
        }else if(e.target.value > 120) {
          this.ctLifeMappRsltInfoSVO.custRtmAge = 120
        }else if(e.target.value < Number(this.ctLifeMappRsltInfoSVO.custCurAge)){
          this.ctLifeMappRsltInfoSVO.custRtmAge = this.ctLifeMappRsltInfoSVO.custCurAge
        }
      },
      fn_chkExptLolAge(e) {
        if(e.target.value < 65) {
          this.ctLifeMappRsltInfoSVO.custExptLolAge = 65
        }else if(e.target.value > 150) {
          this.ctLifeMappRsltInfoSVO.custExptLolAge = 150
        }else if(e.target.value < Number(this.ctLifeMappRsltInfoSVO.custCurAge)){
          this.ctLifeMappRsltInfoSVO.custExptLolAge = 65
        }
      },
      fn_back(){
        this.$router.push( { name: 'MSPCT700M' } )
      },
      fn_openChart() {
        if(this.$refs.watcherStep0.validate() === true){
          // 임시저장
          this.fn_tmpSave()
  
          let objParams = {}
          objParams.step = '1'
          objParams.ctLifeMappRsltInfoSVO = this.$bizUtil.cloneDeep(this.ctLifeMappRsltInfoSVO)
          console.log(objParams)
          this.$router.push( { name: 'MSPCT704M', params : objParams} )
        }
      },
      fn_tmpSave() {
        let lifeMappFileId = this.ctLifeMappRsltInfoSVO.lifeMappFileId
        let tmpFileYn = this.ctLifeMappRsltInfoSVO.tmpFileYn

        if(this.$bizUtil.isEmpty(lifeMappFileId) || tmpFileYn == '1'){
          if(this.$bizUtil.isEmpty(lifeMappFileId)){
            console.log('임시 신규')
            // 임시 신규
            this.fn_tmpNewSave()
          }else{
            console.log('임시 수정')
            // 임시 수정
            this.fn_tmpFileUpdate()
          }
        }
      },
      fn_tmpNewSave() {
        let userId = this.getStore('userInfo').getters.getUserInfo.userId
        let lv_vm = this

        let pParams =
                  { 
                    userEno: userId,
                    cTLifeMappRsltInfo: lv_vm.ctLifeMappRsltInfoSVO
                  }
 
        let trnstId = 'txTSSCT70S3' // 라이프매핑결과 상세정보저장
        console.log('============= pParams  >> ', pParams)

        this.post(lv_vm, pParams, trnstId, 'I').then(function (response) {
          console.log('response ==> ', response)
          if (response.msgComm.msgCd === 'EFWS003') {
            if(response.body.cTLifeMappRsltInfo != null && response.body.cTLifeMappRsltInfo != undefined){
              lv_vm.ctLifeMappRsltInfoSVO.lifeMappFileId = response.body.cTLifeMappRsltInfo.lifeMappFileId
            }
          }else{
            let toastMsg = '임시저장 오류.'
            lv_vm.$addSnackbar(toastMsg)
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
      },
      fn_tmpFileUpdate() {
        let userId = this.getStore('userInfo').getters.getUserInfo.userId
        let lv_vm = this

        let pParams =
                  { 
                    userEno: userId,
                    cTLifeMappRsltInfo: lv_vm.ctLifeMappRsltInfoSVO
                  }
 
        let trnstId = 'txTSSCT70S4' // 라이프매핑결과 상세정보수정
        console.log('============= pParams  >> ', pParams)

        this.post(lv_vm, pParams, trnstId, 'U').then(function (response) {
          console.log('response ==> ', response)
          if (response.msgComm.msgCd === 'EFWS003') {
          }else{
            let toastMsg = '임시저장 오류.'
            lv_vm.$addSnackbar(toastMsg)
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
      }

    }
  };
</script>
                   